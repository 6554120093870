import { NavigationItem } from '@core/models/interfaces/system';

export const SIDEBAR: NavigationItem[] = [
  {
    title: 'sidebar.home',
    icon: 'icon-wl-home',
    path: 'home'
  },
  {
    title: 'sidebar.dashboard',
    icon: 'icon-wl-vertical-bar-chart-square',
    path: 'dashboard'
  },
  {
    title: 'sidebar.leads',
    icon: 'icon-wl-users',
    path: 'lead-management'
  },
  {
    title: 'sidebar.marketing',
    icon: 'icon-wl-speakerphone',
    path: 'marketing'
  },
  {
    title: 'sidebar.tasks',
    icon: 'icon-wl-file-check',
    path: 'tasks'
  },
  {
    title: 'sidebar.policy',
    icon: 'icon-wl-shield-check',
    path: 'policy'
  },
  {
    title: 'sidebar.notes',
    icon: 'icon-wl-note-alt',
    path: 'notes'
  },
  {
    title: 'sidebar.report',
    icon: 'icon-wl-vertical-bar-chart-square',
    path: 'report'
  },
  {
    title: 'sidebar.settings',
    icon: 'icon-wl-setting',
    path: 'setting',
    children: [
      {
        title: 'sidebar.data-config',
        path: 'data-config'
      },
      {
        title: 'sidebar.permissions',
        path: 'permissions'
      },
      {
        title: 'sidebar.user-groups',
        path: 'user-groups'
      },
      {
        title: 'sidebar.company-organization',
        path: 'company-organization'
      },
      {
        title: 'sidebar.users',
        path: 'users'
      },
      {
        title: 'sidebar.admin-content',
        path: 'admin-content'
      }
    ]
  }
];
