import { FieldModuleEnum, FieldTypeEnum } from '@core/enum/data-configuration';

export const FieldTypeOptions = [
  {
    type: FieldTypeEnum.SELECT,
    label: 'Select',
    icon: 'icon-wl-arrow-chevron-circle-down'
  },
  {
    type: FieldTypeEnum.CURRENCY,
    label: 'Currency',
    icon: 'icon-wl-dollar-circle'
  },
  {
    type: FieldTypeEnum.DATETIME,
    label: 'Date',
    icon: 'icon-wl-calendar'
  },
  {
    type: FieldTypeEnum.MULTI_SELECT,
    label: 'Multi-select',
    icon: 'icon-wl-bullet-list'
  },
  {
    type: FieldTypeEnum.NUMBER,
    label: 'Number',
    icon: 'icon-wl-asterisk'
  },
  {
    type: FieldTypeEnum.TEXT,
    label: 'Text',
    icon: 'icon-wl-text'
  },
  {
    type: FieldTypeEnum.DEPENDENT_DROPDOWN,
    label: 'Dependent dropdown',
    icon: 'icon-wl-chevrons-down'
  }
];

export const FieldModuleOptions = [
  {
    label: 'Lead',
    value: FieldModuleEnum.LEAD
  },
  {
    label: 'Note',
    value: FieldModuleEnum.NOTE
  },
  {
    label: 'Policy',
    value: FieldModuleEnum.POLICY
  },
  {
    label: 'Task',
    value: FieldModuleEnum.TASK
  },
  {
    label: 'User',
    value: FieldModuleEnum.USER
  }
];
