<div class="flex flex-col gap-3">
  <div class="flex items-center gap-2">
    <i
      class="icon-wl-arrow-drop-down text-6 cursor-pointer transition"
      [ngClass]="isExpanded ? '' : '-rotate-90'"
      (click)="onToggleExpanded()"></i>
    <span class="text-4 font-semibold">
      {{ group.contactName }}
    </span>
    <div
      class="h-6 min-w-6 w-fit flex items-center justify-center text-neutral-800 bg-neutral-300 rounded-sm font-medium">
      {{ totalNotes }}
    </div>
  </div>

  @if (isExpanded) {
    <div class="flex flex-col gap-3">
      <div class="relative">
        <div class="flex flex-col gap-4">
          @for (item of leadNotes; track group.id) {
            <app-lead-note-item
              [isExpanded]="true"
              [statuses]="noteStatuses"
              [leadId]="leadId"
              [note]="item"
              (deleteNote)="onDeleteNote()"
              (onRefresh)="fetchLeadNotesGroupBy()"></app-lead-note-item>
          }
        </div>
      </div>

      @if (totalNotes > 5) {
        <div
          class="w-fit px-2 h-7 flex items-center justify-center text-primary-600 font-medium cursor-pointer hover:text-primary-700"
          (click)="onToggleView()">
          @if (isLoading) {
            <i class="pi pi-spin pi-spinner"></i>
          } @else {
            {{ (isMore ? 'action.view-less' : 'action.view-more') | translate | capitalize }}
          }
        </div>
      }
    </div>
  }
</div>
