import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { NgxDocViewerModule, viewerType as ViewerType } from 'ngx-doc-viewer';

import { TYPE_FILE } from '@core/enum/attachment';
import { AttachmentInfo } from '@core/models/interfaces/attachment';
import { StorageService } from '@core/services/storage.service';
import { FileIconDirective } from '@shared/directives/file-icon.directive';
import { EmptyComponent } from '../empty/empty.component';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-preview-attachment',
  standalone: true,
  imports: [NgxDocViewerModule, FileIconDirective, EmptyComponent, ButtonComponent, TranslateModule],
  templateUrl: './preview-attachment.component.html',
  styleUrl: './preview-attachment.component.scss'
})
export class PreviewAttachmentComponent {
  readonly messagePrefix = 'lead-details.message.';
  readonly TYPE_FILE = TYPE_FILE
  url = '';
  viewer: ViewerType = 'url';
  file: AttachmentInfo;
  type: 'DOCUMENT' | 'IMAGE' | 'VIDEO' = 'DOCUMENT';
  isSupport = true;
  isNotFound = false;

  constructor(
    private storageService: StorageService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private translator: TranslateService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      const path = queryParams?.['path'];
      if (path) {
        this.storageService.getFileInfo(path).subscribe({
          next: res => {
            const fileType = res.data.type as TYPE_FILE;
            this.url = this.storageService.getFileUrl(res.data.id);
            this.file = res.data;

            if ([TYPE_FILE.DOCX, TYPE_FILE.XLSX].includes(fileType)) {
              this.viewer = 'office';
              this.type = 'DOCUMENT';
            } else if ([TYPE_FILE.PDF].includes(fileType)) {
              this.viewer = 'url';
              this.type = 'DOCUMENT';
            } else if ([TYPE_FILE.JPEG, TYPE_FILE.PNG].includes(fileType)) {
              this.type = 'IMAGE';
            } else if ([TYPE_FILE.MP4].includes(fileType)) {
              this.type = 'VIDEO';
            } else {
              this.isSupport = false;
            }
          },
          error: () => {
            this.isNotFound = true;
          }
        });
      }
    });
  }

  onDownloadFile() {
    if (!this.file) return;
    this.storageService.getFile(this.file.id).subscribe({
      next: res => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(res);
        link.download = this.file.originalName;
        link.click();
      },
      error: err => {
        this.messageService.add({
          severity: 'error',
          detail: err?.error?.error || this.translator.instant(this.messagePrefix + 'download-attachment-failed')
        });
      }
    });
  }
}
