import { createReducer, on } from '@ngrx/store';
import {
  login,
  loginFailed,
  loginSuccess,
  logout,
  register,
  registerFailed,
  registerSuccess,
  setAuthState,
} from './auth.actions';
import { UserInfo } from '@core/models/interfaces/auth';
export interface AuthState {
  accessToken: string;
  error: string;
  isLogging: boolean;
  isRegistering: boolean;
  isLoggedIn: boolean;
  userInfo?: UserInfo;
}

const initialState: AuthState = {
  accessToken: '',
  error: '',
  userInfo: undefined,
  isLogging: false,
  isRegistering: false,
  isLoggedIn: false,
};

export const authReducer = createReducer(
  initialState,
  on(login, state => ({
    ...state,
    error: '',
    isLogging: true,
  })),

  on(loginSuccess, (state, { accessToken, userInfo }) => ({
    ...state,
    accessToken,
    userInfo,
    isLogging: false,
    isLoggedIn: true,
  })),

  on(loginFailed, (state, { error }) => ({
    ...state,
    error,
    isLogging: false,
  })),

  on(register, state => ({
    ...state,
    isRegistering: true,
  })),

  on(registerSuccess, state => ({
    ...state,
    isRegistering: false,
  })),

  on(registerFailed, (state, { error }) => ({
    ...state,
    error,
    isRegistering: false,
  })),

  on(setAuthState, (state, { userInfo, accessToken }) => ({ ...state, userInfo, isLoggedIn: true, accessToken })),

  on(logout, state => ({
    ...state,
    isLoggedIn: false,
    userInfo: undefined,
  }))
);
