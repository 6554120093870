import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';

import { FieldModuleOptions, FieldTypeOptions } from '@core/constants/data-configuration';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomAvatarComponent } from '@shared/components/custom-avatar/custom-avatar.component';
import { SearchInputComponent } from '@shared/components/search-input/search-input.component';
import { Tab, TabsComponent } from '@shared/components/tabs/tabs.component';
import { FieldEditComponent } from './components/field-edit/field-edit.component';
import { ModeAction } from '@core/models/interfaces/common';
import { DynamicFieldService } from '@core/services/data-config/dynamic-field.service';
import { EmptyTableComponent } from '@shared/components/empty-table/empty-table.component';
import { FieldModuleEnum } from '@core/enum/data-configuration';
import { DynamicFieldDetails } from '@core/models/interfaces/data-config/dynamic-field';

@Component({
  selector: 'app-data-configuration',
  standalone: true,
  imports: [
    CommonModule,
    TableModule,
    TabsComponent,
    CheckboxModule,
    FormsModule,
    TranslateModule,

    SearchInputComponent,
    ButtonComponent,
    CustomAvatarComponent,
    FieldEditComponent,
    EmptyTableComponent
  ],
  templateUrl: './data-configuration.component.html',
  styleUrl: './data-configuration.component.scss'
})
export class DataConfigurationComponent implements OnInit, OnDestroy {
  readonly translatePrefix = 'data-configuration.';
  readonly messagePrefix = 'data-configuration.message.';
  readonly unsubscribe$ = new Subject();
  readonly FieldModuleEnum = FieldModuleEnum;

  mode: ModeAction = 'CREATE';
  selectedId: string = '';
  isLoading: boolean = false;
  isEditField: boolean = false;
  tabs: Tab[] = FieldModuleOptions;
  tabActive: Tab;
  keyword: string = '';

  dataConfig: DynamicFieldDetails[] = [];

  constructor(
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private dynamicFieldService: DynamicFieldService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.tabActive = FieldModuleOptions[0];
    this.fetchDataConfig();
  }

  get isDataTable() {
    return this.dataConfig.length;
  }

  fetchDataConfig() {
    this.isLoading = true;
    this.dynamicFieldService
      .getDynamicFields({
        eModule: this.tabActive.value as FieldModuleEnum,
        keyword: this.keyword,
        size: 20
      })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: res => {
          this.isLoading = false;
          this.dataConfig = res.data.content;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  onCreateField() {
    this.selectedId = '';
    this.isEditField = true;
    this.mode = 'CREATE';
  }

  getField(type: string) {
    return FieldTypeOptions.find(option => option.type === type);
  }

  onEditField(id: string) {
    this.selectedId = id;
    this.isEditField = true;
    this.mode = 'EDIT';
  }

  onDeleteField(id: string, name: string) {
    this.confirmationService.confirm({
      message: this.translateService.instant(this.messagePrefix + 'confirm-delete-field', {
        name: name
      }),
      header: this.translateService.instant(this.translatePrefix + 'delete-field'),
      acceptButtonStyleClass: 'btn-xl btn-danger',
      rejectButtonStyleClass: 'btn-xl btn-outline-secondary',
      rejectLabel: this.translateService.instant('action.cancel'),
      acceptLabel: this.translateService.instant('action.delete'),
      acceptIcon: 'none',
      rejectIcon: 'none',

      accept: () => {
        this.dynamicFieldService
          .deleteDynamicFieldById(id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe({
            next: () => {
              this.messageService.add({
                severity: 'success',
                detail: this.translateService.instant(this.messagePrefix + 'delete-field-success')
              });

              this.fetchDataConfig();
            },
            error: () => {
              this.messageService.add({
                severity: 'error',
                detail: this.translateService.instant(this.messagePrefix + 'delete-field-failed')
              });
            }
          });
      }
    });
  }

  onSearchField(event: string) {
    this.keyword = event;
    this.fetchDataConfig();
  }

  onTabChange() {
    this.fetchDataConfig();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
