import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OverlayModule } from 'primeng/overlay';
import { Colors } from '@core/constants/common';
import { ColorEnum } from '@core/enum/common';

@Component({
  selector: 'app-color-picker',
  standalone: true,
  imports: [CommonModule, OverlayModule],
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})
export class ColorPickerComponent {
  @Input() value: ColorEnum = ColorEnum.BLUE;
  @Output() valueChange = new EventEmitter<ColorEnum>();

  readonly Colors = Colors;
  readonly ColorEnum = ColorEnum;

  visible: boolean = false;

  get color() {
    return Colors.find(item => item.value === this.value);
  }

  onSelectColor(value: ColorEnum) {
    this.visible = false;
    this.value = value;
    this.valueChange.emit(this.value);
  }
}
