import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LANGUAGES } from '@core/constants/coutries';
import { LocalStorageEnum } from '@core/enum/local-storage';
import { LanguageItem } from '@core/models/interfaces/system';
import { TranslateService } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';

@Component({
  selector: 'app-flags',
  standalone: true,
  imports: [CommonModule, DropdownModule, FormsModule],
  templateUrl: './flags.component.html',
  styleUrl: './flags.component.scss',
})
export class FlagsComponent {
  readonly languages = LANGUAGES;
  selectedLanguage: LanguageItem;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const language = localStorage.getItem(LocalStorageEnum.LANGUAGE) || this.translateService.getDefaultLang();
    this.selectedLanguage = this.languages.find(c => c.language === language) || this.languages[0];
  }

  changeLocale(item: LanguageItem) {
    const language = (item.language as string) || localStorage.getItem('lang') || 'en';
    this.translateService.use(language);

    localStorage.setItem(LocalStorageEnum.LANGUAGE, language);
  }
}
