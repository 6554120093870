import { Routes } from '@angular/router';
import { authGuard } from '@core/guards/auth.guard';
import { AuthLayoutComponent } from '@core/layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from '@core/layouts/main-layout/main-layout.component';
import { AdminContentComponent } from '@feature/admin-content/admin-content.component';
import { CompanyOrganizationComponent } from '@feature/company-organization/company-organization.component';
import { DashboardComponent } from '@feature/dashboard/dashboard.component';
import { DataConfigurationComponent } from '@feature/data-configuration/data-configuration.component';
import { MarketingComponent } from '@feature/marketing/marketing.component';
import { NoteManagementComponent } from '@feature/note/pages/note-management/note-management.component';
import { ReportComponent } from '@feature/report/report.component';
import { PreviewAttachmentComponent } from '@shared/components/preview-attachment/preview-attachment.component';
import { NotFoundComponent } from '@shared/pages/not-found/not-found.component';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./features/home-page/home-page.routes').then(feature => feature.routes)
      },

      { path: 'dashboard', component: DashboardComponent },

      {
        path: 'lead-management',
        loadChildren: () => import('./features/lead-management/lead-management.routes').then(feature => feature.routes)
      },
      {
        path: 'permissions',
        loadChildren: () => import('./features/permissions/permissions.routes').then(feature => feature.routes)
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./features/lead-view/lead-view.routes').then(feature => feature.routes)
      },
      { path: 'marketing', component: MarketingComponent },

      {
        path: 'tasks',
        loadChildren: () => import('./features/task-management/task-management.routes').then(feature => feature.routes)
      },

      {
        path: 'policy',
        loadChildren: () => import('./features/policy/policy-management.routes').then(feature => feature.routes)
      },

      { path: 'notes', component: NoteManagementComponent },

      { path: 'report', component: ReportComponent },

      { path: 'data-config', component: DataConfigurationComponent },

      {
        path: 'permissions',
        loadChildren: () => import('./features/permissions/permissions.routes').then(feature => feature.routes)
      },

      {
        path: 'lead-viewers',
        loadChildren: () => import('./features/lead-view/lead-view.routes').then(feature => feature.routes)
      },

      { path: 'company-organization', component: CompanyOrganizationComponent },

      {
        path: 'users',
        loadChildren: () => import('./features/user-management/user-management.routes').then(feature => feature.routes)
      },

      { path: 'admin-content', component: AdminContentComponent },

      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'prefix'
      }
    ]
  },
  {
    path: 'file',
    component: PreviewAttachmentComponent,
    canActivate: [authGuard]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () => import('./features/auth/auth.routes').then(feature => feature.routes)
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent
  }
];
