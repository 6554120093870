import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import { AuthState } from '@state/auth/auth.reducers';
import { map } from 'rxjs';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  const store = inject(Store<AppState>);

  return store
    .select(appState => appState.auth)
    .pipe(
      map((authState: AuthState) => {
        if (!authState.accessToken || !authState.userInfo) {
          router.navigate(['/login']);
          return false;
        }
        return true;
      })
    );
};
