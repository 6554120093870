import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { LANGUAGES } from '@core/constants/translate';
import { ToastMessageComponent } from '@shared/components/toast-message/toast-message.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, ToastMessageComponent, CommonModule, ConfirmDialogModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: [MessageService, ConfirmationService]
})
export class AppComponent implements OnInit {
  constructor(
    private translateService: TranslateService,
    private primeNGConfig: PrimeNGConfig,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(environment.APP_NAME);
    this.initLanguage();
  }

  initLanguage() {
    const language = localStorage.getItem('lang') as string;
    this.translateService.use(LANGUAGES.includes(language) ? language : 'en');

    this.translateService.get('PRIMENG').subscribe(res => {
      this.primeNGConfig.setTranslation(res);
    });
  }
}
