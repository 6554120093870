import { Component } from '@angular/core';

@Component({
  selector: 'app-company-organization',
  standalone: true,
  imports: [],
  templateUrl: './company-organization.component.html',
  styleUrl: './company-organization.component.scss'
})
export class CompanyOrganizationComponent {

}
