import { createReducer, on } from '@ngrx/store';
import { setIsSidebar } from './system.actions';

export interface SystemState {
  isSidebar: boolean;
}

const initialState: SystemState = {
  isSidebar: false,
};

export const systemReducer = createReducer(
  initialState,
  on(setIsSidebar, (state, { isSidebar }) => ({
    ...state,
    isSidebar: isSidebar,
  }))
);
