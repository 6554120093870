<app-custom-dialog
  [visible]="visible"
  (visibleChange)="onVisibleChange($event)"
  [header]="translatePrefix + (mode === 'CREATE' ? 'create-field' : 'edit-field') | translate">
  <div content>
    @if (isLoading) {
      <app-custom-loading styleClass="min-h-[30vh] flex items-center"></app-custom-loading>
    } @else {
      <form [formGroup]="fieldForm" class="flex flex-col gap-6">
        <!-- GENERAL INFORMATION -->
        <div class="flex flex-col gap-3">
          <span class="text-2 font-semibold uppercase">{{ translatePrefix + 'general-info' | translate }}</span>
          <app-form-control
            [label]="translatePrefix + 'field-name' | translate"
            [required]="true"
            [floatLabel]="true"
            [control]="fieldNameControl">
            <input input pInputText formControlName="name" class="w-full" />
          </app-form-control>
        </div>

        <!-- FIELD TYPE -->
        <div class="flex flex-col gap-3">
          <span class="text-2 font-semibold uppercase">{{ translatePrefix + 'field-type' | translate }}</span>
          <app-form-control
            [label]="translatePrefix + 'type' | translate"
            [required]="true"
            [floatLabel]="true"
            [control]="fieldTypeControl">
            <p-dropdown
              input
              appendTo="body"
              styleClass="w-full"
              dropdownIcon="icon-wl-arrow-drop-down"
              formControlName="type"
              optionValue="type"
              [options]="FieldTypeOptionsData"
              (onChange)="onChangeFieldType($event)">
              <ng-template pTemplate="selectedItem" let-implicit>
                <div class="flex items-center gap-1">
                  <i class="{{ implicit.icon }} text-6"></i>
                  <span>{{ implicit.label }}</span>
                </div>
              </ng-template>

              <ng-template let-type pTemplate="item">
                <div class="flex items-center gap-2">
                  <i class="{{ type.icon }} text-6"></i>
                  <span>{{ type.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          </app-form-control>

          <!-- REQUIRED -->
          <p-checkbox
            formControlName="required"
            [binary]="true"
            [label]="translatePrefix + 'required' | translate"
            labelStyleClass="font-medium"></p-checkbox>
        </div>

        <!-- OPTIONS -->
        @if (
          fieldTypeControl.value === FieldTypeEnum.SELECT ||
          fieldTypeControl.value === FieldTypeEnum.MULTI_SELECT ||
          fieldTypeControl.value === FieldTypeEnum.DEPENDENT_DROPDOWN
        ) {
          <div>
            <div class="flex items-center justify-between">
              <span class="text-2 font-semibold uppercase">{{ translatePrefix + 'options' | translate }}</span>

              <!-- TAG COLOR -->
              @if (fieldTypeControl.value !== FieldTypeEnum.MULTI_SELECT) {
                <div class="flex items-center gap-2">
                  <p-inputSwitch formControlName="tagColor"></p-inputSwitch>
                  <span class="font-medium">{{ translatePrefix + 'tag-colors' | translate }}</span>
                </div>
              }
            </div>
            <div
              cdkDropList
              class="flex flex-col gap-3 mb-3"
              [ngClass]="{ 'mt-3': fieldOptionFormArray?.controls?.length }"
              (cdkDropListDropped)="onDropOption($event)"
              formArrayName="options">
              @for (item of fieldOptionFormArray?.controls; track i; let i = $index) {
                @if (
                  fieldTypeControl.value === FieldTypeEnum.SELECT ||
                  fieldTypeControl.value === FieldTypeEnum.MULTI_SELECT
                ) {
                  <!-- SELECT & MULTI-SELECT -->
                  <div class="flex items-center gap-3 bg-white" cdkDrag [formGroupName]="i">
                    <div class="w-5 h-full flex items-center justify-center cursor-grab" cdkDragHandle>
                      <i class="icon-wl-drag-indicator text-6 text-neutral-600"></i>
                    </div>
                    <div class="flex items-center justify-between py-2 px-3 rounded-sm border flex-1">
                      <input
                        type="text"
                        class="flex-1"
                        [formControl]="fieldOptionName(i)"
                        [placeholder]="translatePrefix + 'enter-option' | translate" />

                      @if (fieldTypeControl.value === FieldTypeEnum.SELECT && tagColorControl.value) {
                        <app-color-picker
                          [value]="fieldOptionColor(i).value"
                          (valueChange)="onChangeOptionColor($event, i)"></app-color-picker>
                      }
                    </div>
                    <i
                      class="icon-wl-close text-6"
                      [ngClass]="
                        fieldOptionFormArray.value.length > 1
                          ? 'text-neutral-600 hover:text-red-600 transition cursor-pointer'
                          : 'text-neutral-alpha-500A'
                      "
                      (click)="onDeleteFieldOption(i)"></i>
                  </div>
                } @else if (fieldTypeControl.value === FieldTypeEnum.DEPENDENT_DROPDOWN) {
                  <!-- DEPENDENT-DROPDOWN -->
                  <div cdkDrag [formGroupName]="i">
                    <div class="flex items-center gap-3 bg-white">
                      <div class="w-5 h-full flex items-center justify-center cursor-grab" cdkDragHandle>
                        <i class="icon-wl-drag-indicator text-6 text-neutral-600"></i>
                      </div>
                      <div class="flex items-center justify-between py-2 px-3 rounded-sm border flex-1">
                        <input
                          type="text"
                          class="flex-1"
                          [formControl]="fieldOptionName(i)"
                          [placeholder]="translatePrefix + 'enter-option' | translate" />

                        @if (tagColorControl.value) {
                          <app-color-picker
                            [value]="fieldOptionColor(i).value"
                            (valueChange)="onChangeOptionColor($event, i)"></app-color-picker>
                        }
                      </div>
                      <i
                        class="icon-wl-close text-6"
                        [ngClass]="
                          fieldOptionFormArray.value.length > 1
                            ? 'text-neutral-600 hover:text-red-600 transition cursor-pointer'
                            : 'text-neutral-alpha-500A'
                        "
                        (click)="onDeleteFieldOption(i)"></i>
                    </div>

                    <!-- CHILDREN -->
                    <div
                      class="px-8 flex flex-col gap-2 mt-3"
                      cdkDropList
                      formArrayName="children"
                      (cdkDropListDropped)="onDropChildOption($event, i)">
                      @for (child of fieldOptionChildren(i).controls; track j; let j = $index) {
                        <div class="flex items-center gap-3 bg-white" cdkDrag [formGroupName]="j">
                          <div class="w-5 h-full flex items-center justify-center cursor-grab" cdkDragHandle>
                            <i class="icon-wl-drag-indicator text-6 text-neutral-600"></i>
                          </div>
                          <div class="flex-1 py-2 px-3 rounded-sm border">
                            <input
                              type="text"
                              [formControl]="fieldOptionChild(i, j)"
                              [placeholder]="translatePrefix + 'enter-option' | translate" />
                          </div>
                          <i
                            class="icon-wl-close text-6 text-neutral-600"
                            [ngClass]="
                              fieldOptionChildren(i).value.length > 1
                                ? 'text-neutral-600 hover:text-red-600 transition cursor-pointer'
                                : 'text-neutral-alpha-500A'
                            "
                            (click)="onDeleteFieldChildOption(i, j)"></i>
                        </div>
                      }
                      <!-- ADD CHILD OPTION -->
                      <app-button
                        styleClass="ml-8"
                        [label]="translatePrefix + 'add-child-option' | translate"
                        variant="outline-secondary"
                        icon="icon-wl-plus"
                        size="sm"
                        iconPosition="left"
                        (onClick)="onAddFieldChildOption(i)"></app-button>
                    </div>
                  </div>
                }
              }
            </div>

            <div [ngClass]="{ 'ml-8': fieldOptionFormArray?.controls?.length }">
              <app-button
                [label]="translatePrefix + 'add-option' | translate"
                variant="outline-secondary"
                icon="icon-wl-plus"
                size="sm"
                iconPosition="left"
                (onClick)="onAddFieldOption()"></app-button>
            </div>
          </div>
        }
      </form>
    }
  </div>
  <div footer>
    <app-button
      variant="outline-secondary"
      [label]="'action.cancel' | translate"
      size="xl"
      (onClick)="onCancel()"></app-button>
    <app-button
      [label]="(mode === 'CREATE' ? 'action.create' : 'action.save') | translate"
      size="xl"
      [loading]="isEditing"
      (onClick)="onSave()"></app-button>
  </div>
</app-custom-dialog>
