<!-- BOTTOM-LEFT -->
<p-toast [position]="'top-right'" [styleClass]="'right-1 max-w-[100vw]'">
  <ng-template let-message pTemplate="message">
    <ng-container [ngTemplateOutletContext]="{ message: message }" [ngTemplateOutlet]="messageTemplate"></ng-container>
  </ng-template>
</p-toast>

<!-- TEMPLATE -->
<ng-template #messageTemplate let-message="message">
  <div class="flex items-center gap-3">
    @switch (message.severity) {
      @case ('info') {
        <i class="text-6 icon-wl-info-circle"></i>
      }
      @case ('success') {
        <i class="text-6 icon-wl-check-circle"></i>
      }
      @case ('warn') {
        <i class="text-6 icon-wl-warning"></i>
      }
      @case ('error') {
        <i class="text-6 icon-wl-error-circle"></i>
      }
      @case ('contrast') {
        <i class="text-6 icon-wl-stars"></i>
      }
    }
    <div class="toast-content">
      <span class="font-semibold">
        {{ message.summary }}
      </span>
      <span>
        {{ message.detail }}
      </span>
    </div>
  </div>
</ng-template>
