import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { delay, Subject, takeUntil } from 'rxjs';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OrderListModule } from 'primeng/orderlist';
import { OverlayModule } from 'primeng/overlay';
import { MessageService } from 'primeng/api';

import { Colors } from '@core/constants/common';
import { FieldTypeOptions } from '@core/constants/data-configuration';
import { ColorEnum } from '@core/enum/common';
import { FieldTypeEnum } from '@core/enum/data-configuration';
import { ModeAction } from '@core/models/interfaces/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ButtonComponent } from '@shared/components/button/button.component';
import { CustomDialogComponent } from '@shared/components/custom-dialog/custom-dialog.component';
import { FormControlComponent } from '@shared/components/form-control/form-control.component';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { DynamicFieldService } from '@core/services/data-config/dynamic-field.service';
import { CustomLoadingComponent } from '@shared/components/custom-loading/custom-loading.component';
import { DynamicFieldDetails, FieldFormOption } from '@core/models/interfaces/data-config/dynamic-field';

@Component({
  selector: 'app-field-edit',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    CheckboxModule,
    OrderListModule,
    DragDropModule,
    OverlayModule,
    InputSwitchModule,
    TranslateModule,

    ColorPickerComponent,
    CustomDialogComponent,
    ButtonComponent,
    FormControlComponent,
    CustomLoadingComponent
  ],
  templateUrl: './field-edit.component.html',
  styleUrl: './field-edit.component.scss'
})
export class FieldEditComponent implements OnChanges, OnDestroy {
  @Input() module: string = '';
  @Input() mode: ModeAction = 'CREATE';
  @Input() selectedId: string = '';
  @Input() visible: boolean = false;

  @Output() visibleChange = new EventEmitter();
  @Output() refreshList = new EventEmitter();

  readonly translatePrefix = 'data-configuration.';
  readonly messagePrefix = 'data-configuration.message.';
  readonly FieldTypeOptions = FieldTypeOptions;
  readonly Colors = Colors;
  readonly FieldTypeEnum = FieldTypeEnum;
  readonly unsubscribe$ = new Subject();

  fieldForm: FormGroup;
  fieldData: DynamicFieldDetails | null = null;
  isLoading: boolean = false;
  isEditing: boolean = false;

  constructor(
    private fb: FormBuilder,
    private dynamicFieldService: DynamicFieldService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const selectedId = changes?.['selectedId']?.currentValue;

    if (selectedId) {
      // EDIT FIELD (HAVE CHANGE ID)
      this.fetchFieldDetails(selectedId);
    } else if (selectedId !== undefined) {
      // CREATE FIELD
      this.initFieldForm();
      this.fieldData = null;
    } else {
      // EDIT FIELD (NO CHANGE ID)
      if (this.fieldData) {
        this.convertDataToForm(this.fieldData);
      }
    }
  }

  get FieldTypeOptionsData() {
    return FieldTypeOptions.filter(option => option.type !== FieldTypeEnum.DEPENDENT_DROPDOWN);
  }

  fetchFieldDetails(fieldId: string) {
    this.isLoading = true;
    this.dynamicFieldService
      .getDynamicFieldById(fieldId)
      .pipe(takeUntil(this.unsubscribe$), delay(100))
      .subscribe({
        next: res => {
          this.fieldData = res.data;
          this.convertDataToForm(res.data);

          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        }
      });
  }

  convertDataToForm(data: DynamicFieldDetails) {
    this.fieldForm = this.fb.group({
      name: [{ value: data.name, disabled: data.dynamic === false }, [Validators.required]],
      type: [{ value: data.fieldType, disabled: true }, [Validators.required]],
      tagColor: [{ value: data.tagColor, disabled: true }],
      required: [{ value: data.required, disabled: true }],
      options: this.fb.array([])
    });

    if (data.options) {
      for (let option of data.options) {
        this.fieldOptionFormArray.push(
          this.fb.group({
            id: [option.id],
            name: [option.name, Validators.required],
            color: [option.codeColor]
          })
        );
      }
    }
  }

  get fieldNameControl() {
    return this.fieldForm.controls?.['name'] as FormControl;
  }

  get fieldTypeControl() {
    return this.fieldForm.controls?.['type'] as FormControl;
  }

  get tagColorControl() {
    return this.fieldForm.controls?.['tagColor'] as FormControl;
  }

  get fieldOptionFormArray() {
    return this.fieldForm.controls?.['options'] as FormArray;
  }

  fieldOptionColor(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls?.['color'];
  }

  fieldOptionName(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls?.['name'] as FormControl;
  }

  fieldOptionChildren(index: number) {
    return (this.fieldOptionFormArray.at(index) as FormGroup).controls['children'] as FormArray;
  }

  fieldOptionChild(index: number, childIndex: number) {
    return (this.fieldOptionChildren(index).at(childIndex) as FormGroup).controls?.['name'] as FormControl;
  }

  initFieldForm() {
    this.fieldForm = this.fb.group({
      name: this.fb.control(null, [Validators.required]),
      type: this.fb.control(null, [Validators.required]),
      tagColor: this.fb.control(false),
      required: this.fb.control(false)
    });
  }

  onVisibleChange($event: boolean) {
    this.visible = $event;
    this.visibleChange.emit(this.visible);
  }

  onDropOption(event: CdkDragDrop<string[]>) {
    const formArray = this.fieldOptionFormArray;

    moveItemInArray(formArray.controls, event.previousIndex, event.currentIndex);
  }

  onDropChildOption(event: CdkDragDrop<string[]>, index: number) {
    const array = this.fieldOptionChildren(index).value;

    moveItemInArray(array, event.previousIndex, event.currentIndex);
    this.fieldOptionChildren(index).setValue(array);
  }

  onChangeFieldType(event: DropdownChangeEvent) {
    const fieldType = event.value;
    this.fieldForm.removeControl('options');

    switch (fieldType) {
      case FieldTypeEnum.SELECT: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required]),
              color: this.fb.control(ColorEnum.BLUE)
            })
          ])
        );
        break;
      }
      case FieldTypeEnum.MULTI_SELECT: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required])
            })
          ])
        );
        break;
      }
      case FieldTypeEnum.DEPENDENT_DROPDOWN: {
        this.fieldForm.addControl(
          'options',
          this.fb.array([
            this.fb.group({
              name: this.fb.control('', [Validators.required]),
              color: this.fb.control(ColorEnum.BLUE),
              children: this.fb.array([
                this.fb.group({
                  name: this.fb.control('', [Validators.required])
                })
              ])
            })
          ])
        );
      }
    }
  }

  onAddFieldOption() {
    const fieldType = this.fieldForm.getRawValue().type;

    switch (fieldType) {
      case FieldTypeEnum.SELECT: {
        this.fieldOptionFormArray.push(
          this.fb.group({
            name: this.fb.control('', [Validators.required]),
            color: this.fb.control(ColorEnum.BLUE)
          })
        );
        break;
      }
      case FieldTypeEnum.MULTI_SELECT: {
        this.fieldOptionFormArray.push(this.fb.group({ name: this.fb.control('', [Validators.required]) }));
        break;
      }
      case FieldTypeEnum.DEPENDENT_DROPDOWN: {
        this.fieldOptionFormArray.push(
          this.fb.group({
            name: this.fb.control('', [Validators.required]),
            color: this.fb.control(ColorEnum.BLUE),
            children: this.fb.array([
              this.fb.group({
                name: this.fb.control('', [Validators.required])
              })
            ])
          })
        );
        break;
      }
    }
  }

  onAddFieldChildOption(index: number) {
    this.fieldOptionChildren(index).push(
      this.fb.group({
        name: this.fb.control('', [Validators.required])
      })
    );
  }

  onDeleteFieldOption(index: number) {
    if (this.fieldOptionFormArray.value?.length <= 1) return;
    this.fieldOptionFormArray.removeAt(index);
  }

  onDeleteFieldChildOption(index: number, optionIndex: number) {
    if (this.fieldOptionChildren(index).value?.length <= 1) return;
    this.fieldOptionChildren(index).removeAt(optionIndex);
  }

  onChangeOptionColor(event: ColorEnum, index: number) {
    this.fieldOptionColor(index).setValue(event);
  }

  onCancel() {
    this.onVisibleChange(false);
  }

  onSave() {
    this.fieldForm.markAllAsTouched();
    if (this.fieldForm.invalid) return;

    const value = this.fieldForm.getRawValue();
    const body = {
      name: value.name,
      fieldType: value.type,
      required: value.required,
      tagColor: value.tagColor,
      module: this.module,
      optionsRequest: value.options?.map((option: FieldFormOption) => {
        const color = Colors.find(item => item.value === option.color);
        return {
          name: option.name,
          color: color?.textColor,
          codeColor: option.color,
          backgroundColor: color?.bgColor,
          fieldId: option?.id
        };
      })
    };

    const source =
      this.mode === 'CREATE'
        ? this.dynamicFieldService.createDynamicField(body)
        : this.dynamicFieldService.updateDynamicFieldById(this.selectedId, body);

    this.isEditing = true;
    source.subscribe({
      next: res => {
        this.isEditing = false;

        this.messageService.add({
          severity: 'success',
          detail: this.translateService.instant(
            this.messagePrefix + (this.mode === 'CREATE' ? 'create-field-success' : 'update-field-success')
          )
        });

        this.fieldData = res.data;
        if (this.mode === 'CREATE') {
          this.fieldForm.reset();
        }

        this.onVisibleChange(false);
        this.refreshList.emit();
      },
      error: () => {
        this.isEditing = false;

        this.messageService.add({
          severity: 'error',
          detail: this.translateService.instant(
            this.messagePrefix + (this.mode === 'CREATE' ? 'create-field-failed' : 'update-field-failed')
          )
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }
}
