import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, from, map, of, tap } from 'rxjs';

import { login, loginFailed, loginSuccess } from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private router: Router,
    private authService: AuthService
  ) {}

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(login),
      exhaustMap(action => {
        return from(this.authService.login(action.email, action.password)).pipe(
          map(res => {
            localStorage.setItem('accessToken', res.data.token);
            localStorage.setItem('user', JSON.stringify(res.data.userInfo));
            return loginSuccess({
              accessToken: res.data.token,
              userInfo: res.data.userInfo
            });
          }),
          catchError(error => of(loginFailed({ error: error?.error?.code })))
        );
      })
    )
  );

  loginRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(loginSuccess),
        tap(() => {
          this.router.navigateByUrl('/');
        })
      ),
    {
      dispatch: false
    }
  );
}
