import { HttpInterceptorFn } from '@angular/common/http';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken =
    localStorage.getItem('accessToken') ||
    'eyJhbGciOiJIUzI1NiJ9.eyJmdWxsTmFtZSI6IkxlIFF1eSIsInVzZXJJZCI6IjA3ZjJmNzc3LTI5ZWItNDBhNC1iNzBlLTliYjNhYjkyOTI5YiIsImVtYWlsIjoibHZxdXlAdG1hLmNvbS52biIsInN1YiI6IjA3ZjJmNzc3LTI5ZWItNDBhNC1iNzBlLTliYjNhYjkyOTI5YiIsImlhdCI6MTcyMDQ5Mzc4MCwiZXhwIjoxNzIwNTgwMTgwfQ._sZi69RIAIdrpggaih18EL4K6C88thb5oFzoRZ-rVxs';

  // Clone the request and add the authorization header
  const authReq = req.clone({
    setHeaders: {
      Authorization: `Bearer ${authToken}`,
    },
  });

  return next(authReq);
};
