import { Component, HostListener, OnInit } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { AvatarModule } from 'primeng/avatar';
import { OverlayModule } from 'primeng/overlay';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';

import { AppState } from '@state/app.state';
import { setIsSidebar } from '@state/system/system.actions';
import { TranslateModule } from '@ngx-translate/core';
import { selectUserInfo } from '@state/auth/auth.selectors';
import { UserInfo } from '@core/models/interfaces/auth';

import { SearchInputComponent } from '../search-input/search-input.component';
import { FlagsComponent } from '../flags/flags.component';
import { CustomAvatarComponent } from '../custom-avatar/custom-avatar.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    SearchInputComponent,
    BadgeModule,
    TranslateModule,
    AvatarModule,
    CommonModule,
    FlagsComponent,
    OverlayModule,
    CustomAvatarComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  readonly environment = environment;

  SMALL_SCREEN = 1280;
  isSmallScreen = false;
  visible = false;
  userInfo: UserInfo;

  constructor(private store: Store<AppState>) {}

  @HostListener('window:resize', ['$event'])
  checkSmallScreen() {
    this.isSmallScreen = window.innerWidth <= this.SMALL_SCREEN;
  }

  @HostListener('window:scroll', ['$event'])
  scroll() {
    this.visible = false;
  }

  ngOnInit(): void {
    this.checkSmallScreen();

    this.store.select(selectUserInfo).subscribe(userInfo => {
      if (userInfo) {
        this.userInfo = userInfo;
      }
    });
  }

  setSidebar() {
    this.store.dispatch(setIsSidebar({ isSidebar: true }));
  }

  onSignOut() {
    localStorage.clear();
    window.location.reload();
  }
}
