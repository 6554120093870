import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SIDEBAR } from '@core/constants/sidebar';
import { NavigationItem } from '@core/models/interfaces/system';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AppState } from '@state/app.state';
import { setIsSidebar } from '@state/system/system.actions';
import { selectIsSidebar } from '@state/system/system.selectors';
import { SidebarModule } from 'primeng/sidebar';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [CommonModule, SidebarModule, RouterModule, TranslateModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  isMenuShow = true;
  Sidebars = SIDEBAR;
  showChildren: boolean[] = SIDEBAR.map(item => !!item.children);
  isSidebar$ = this.store.select(selectIsSidebar);

  constructor(private store: Store<AppState>) {}

  setIsSidebar(isSidebar: boolean) {
    this.store.dispatch(setIsSidebar({ isSidebar }));
  }

  onClickItem(item: NavigationItem, index: number) {
    if (item.children) {
      this.showChildren[index] = !this.showChildren[index];
    } else {
      this.setIsSidebar(false);
    }
  }
}
